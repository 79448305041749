import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { Collection } from "../../elemental/collection/Collection";
import { AppBar } from "@/components/app/appBar/AppBar";
import { PageNode } from "@/components/page/pageNode/PageNode";
import { Language } from "@/components/shared/model/BaseVars";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";
import { Theme } from "@/components/shared/theme/Theme";

export
class App extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "App", friendlyName: "App"};

  static readonly nameAppBar: string = "AppBar";
  static readonly namePageNodes: string = "PageNodes";

  appBar = new AppBar(App.nameAppBar);
  pageNodes = new Collection<PageNode>(App.namePageNodes, [PageNode]);
  theme = new Theme();

  public getStoreObjectItem(): Object{
    return {
      ref: this.ref,
      appBar: this.appBar.getStoreObject(),
      pageNodes: this.pageNodes.getStoreObject(),
      theme: this.theme.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.ref = item.ref;
    this.appBar.fromStoreObject(item.appBar);
    this.pageNodes.fromStoreObject(item.pageNodes);
    this.theme.fromStoreObject(item.theme);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const app = item as App;
    this.appBar.from(app.appBar);
    this.pageNodes.from(app.pageNodes);
  }

  public getPageNode(pageUrlOrId: string, language: Language): PageNode | undefined{
    pageUrlOrId = pageUrlOrId.toLowerCase();
    for (let i = 0; i<this.pageNodes.getElements().length; ++i){
      const pageNode = (this.pageNodes.getElements()[i] as PageNode);
      if (pageNode.pageRef.getRefId().toLowerCase() === pageUrlOrId){
        return pageNode;
      }

      const url = pageNode.url.getValueOf(language);
      //substring(1): internal url starts with "/"
      if (url.startsWith("/") && url.substring(1).toLowerCase() === pageUrlOrId){
        return pageNode;
      }
    }
  }

  public getHomePageNode(): PageNode{
    return this.pageNodes.getElements()[0] as PageNode;
  }

  getMeta(): MetaInfo{
    return App.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new App();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}