import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refLocation,
    context: $setup.props.context
  }, {
    body: _withCtx(() => [
      _createVNode(_component_GoogleMap, {
        "api-key": "AIzaSyD4N5eT3kiZLivsX8qP7GlzX-kuBSB7sj0",
        style: {"width":"100%","height":"100%"},
        center: { lat: Number($setup.refLocation.lat.getValueOf($setup.props.context.language)), lng: Number($setup.refLocation.long.getValueOf($setup.props.context.language)) },
        zoom: 15
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Marker, { options: { position: 'center' } })
        ]),
        _: 1
      }, 8, ["center"])
    ]),
    _: 1
  }, 8, ["item", "context"]))
}