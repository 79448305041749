import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58a363c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "TextArea" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "TextArea" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Contained = _resolveComponent("Contained")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refProductPreview,
    context: $setup.refContext
  }, {
    body: _withCtx(() => [
      _createVNode(_component_Contained, {
        styleContained: $setup.refStyleContained,
        context: $setup.refContext
      }, {
        body: _withCtx(() => [
          ($setup.refContext.mode.editContent || $setup.refContext.mode.editLayout || $setup.refContext.mode.editTextInline)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("img", {
                  draggable: "false",
                  class: "Unselectable Thumbnail Image",
                  src: $setup.computedProduct.thumbnail.url.getValueOf($setup.refContext.language),
                  alt: $setup.computedProduct.thumbnail.altText.getValueOf($setup.refContext.language)
                }, null, 8, _hoisted_1),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", {
                    class: "Text Unselectable",
                    style: _normalizeStyle($setup.getButtonStyle())
                  }, _toDisplayString($setup.computedProduct.title.getValueOf($setup.refContext.language)), 5),
                  _createElementVNode("p", {
                    class: "Text Unselectable",
                    style: _normalizeStyle($setup.getButtonStyle())
                  }, _toDisplayString($setup.computedProduct.price.getValueOf($setup.refContext.language)), 5)
                ])
              ], 64))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: "InheritSize",
                to: {path: $setup.getPathForPage('/' + $setup.refProductPreview.productRef.getRefId()), query: _ctx.$route.query}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    draggable: "false",
                    class: "Unselectable Thumbnail Image",
                    src: $setup.computedProduct.thumbnail.url.getValueOf($setup.refContext.language),
                    alt: $setup.computedProduct.thumbnail.altText.getValueOf($setup.refContext.language)
                  }, null, 8, _hoisted_3),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", {
                      class: "Text Unselectable",
                      style: _normalizeStyle($setup.getButtonStyle())
                    }, _toDisplayString($setup.computedProduct.title.getValueOf($setup.refContext.language)), 5),
                    _createElementVNode("p", {
                      class: "Text Unselectable",
                      style: _normalizeStyle($setup.getButtonStyle())
                    }, _toDisplayString($setup.computedProduct.price.getValueOf($setup.refContext.language)), 5)
                  ])
                ]),
                _: 1
              }, 8, ["to"]))
        ]),
        _: 1
      }, 8, ["styleContained", "context"])
    ]),
    _: 1
  }, 8, ["item", "context"]))
}