import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import Style from "./view/default/Style";
import { Language } from "@/components/shared/model/BaseVars";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class Location extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Location", friendlyName: "Location"};

  static readonly nameLong: string = "Long";
  static readonly nameLat: string = "Lat";

  long = new PropertyString(Location.nameLong);
  lat = new PropertyString(Location.nameLat);

  public getStoreObjectItem(): Object{
    return {
      long: this.long.getStoreObject(),
      lat: this.lat.getStoreObject(),
    };
  }

  public fromStoreObjectItem(item: any){
    this.long.fromStoreObject(item.long);
    this.lat.fromStoreObject(item.lat);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.lat.setValueOf(Language.en, "40.689247");
    this.long.setValueOf(Language.en, "-74.044502");

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const location = item as Location;
    this.long.from(location.long);
    this.lat.from(location.lat);
  }

  getMeta(): MetaInfo{
    return Location.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Location();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}