import { BaseStyle } from "@/components/shared/model/BaseStyle";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import Style from "./view/default/Style";
import ViewFactory from "./view/ViewFactory";
import { AnchorTree, AnchorNode } from "./view/shared/AnchorTree";
import { AnchorPosition, FluidElement, Rect, Spatial } from "./view/shared/FluidElement";
import { TreeNode } from "./view/shared/Tree";

export
class FluidContainer extends BaseItem{
  static readonly meta: MetaInfo = {typeName: "FluidContainer", friendlyName: "Fluid Container"};

  static readonly nameContent: string = "Content";

  anchorTree = new AnchorTree(); // not cloned or stored or compared (yet)

  public getStoreObjectItem(): Object{
    // console.log("FluidContainer.getStoreObjectItem: ", this.anchorTree.getStoreObject());
    return {
      anchorTree: this.anchorTree.getStoreObject(),
    };
  }

  public fromStoreObjectItem(item: any){
    // console.log("FluidContainer.fromStoreObjectItem: ", item.anchorTree);
    this.anchorTree.fromStoreObject(item.anchorTree);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const fluidContainer = item as FluidContainer;
    this.anchorTree.from(fluidContainer.anchorTree);
  }

  getMeta(): MetaInfo{
    return FluidContainer.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new FluidContainer();
  }

  public contains(item: BaseItem): BaseItem[]{
    const tmp = [] as BaseItem[];
    this.anchorTree.traverse(
      this.anchorTree.root,
      (child: TreeNode<FluidElement>, parent: TreeNode<FluidElement>):boolean => {
        if (child.getContent().ref === item.ref){
          tmp.push(...[child.getContent(), this]);
          return true;
        }
        tmp.push( ...child.getContent().contains(item) );
        if (tmp.length>0){
          tmp.push(this);
          return true;
        }
        return false;
      }
    );
    return tmp;
  }

  public addElementToAnchorTree(spatial: Spatial, item: BaseItem) : AnchorNode{
    if (this.anchorTree.root!.children.length===0){
      spatial.z = 0;
    } else {
      spatial.z = this.getMaxZ() + 1;
    }
    return this.anchorTree.addElement(new FluidElement(spatial, item));
  }

  public addElement(item: BaseItem, x: number, y: number, width: number, height: number, ifStickToContentHeight=false): FluidElement{
    let lowestElementNode = null as null | AnchorNode;
    this.anchorTree.traverse(this.anchorTree.root, (childNode: AnchorNode, parentNode: AnchorNode): boolean=>{
      if (lowestElementNode === null ){
        lowestElementNode = childNode;
        return false;
      }
      if (childNode.getContent().spatial.desired.y + childNode.getContent().spatial.desired.height >
        lowestElementNode.getContent().spatial.desired.y + lowestElementNode.getContent().spatial.desired.height){
        lowestElementNode = childNode;
        return false;
      }
      return false;
    });

    const spatial = new Spatial(new Rect(x,y,width,height), new Rect(x,y,width,height), 0, 0, ifStickToContentHeight);
    const element = new FluidElement(spatial, item);
    const elementNode = this.addElementToAnchorTree(spatial, item);

    if (lowestElementNode !== null){
      this.anchorTree.link(elementNode, lowestElementNode, AnchorPosition.Top, AnchorPosition.Bottom);
    }

    this.anchorTree.updateElementArray();

    return element;
  }

  public removeElement(element: FluidElement){
    const elementZ = element.spatial.z;
    this.anchorTree.removeElement(element);
    // Decrease z of elements above this element
    this.anchorTree.traverse(this.anchorTree.root, (childNode: AnchorNode, parentNode: AnchorNode): boolean=>{
      if (childNode.getContent().spatial.z > elementZ){
        childNode.getContent().spatial.z = childNode.getContent().spatial.z - 1;
      }
      return false;
    });
    this.anchorTree.updateElementArray();
  }

  private getMaxZ(): number{
    let maxZ = 0;
    const findMaxZ = (childNode: AnchorNode, parentNode: AnchorNode): boolean=>{
      if (childNode.getContent().spatial.z >= maxZ){
        maxZ =  childNode.getContent().spatial.z;
      }
      return false;
    };
    this.anchorTree.traverse(this.anchorTree.root, findMaxZ);
    return maxZ;
  }

  private getMinZ(): number{
    let minZ = Number.MAX_SAFE_INTEGER;
    const findMinZ = (childNode: AnchorNode, parentNode: AnchorNode): boolean=>{
      if (childNode.getContent().spatial.z <= minZ){
        minZ =  childNode.getContent().spatial.z;
      }
      return false;
    };
    this.anchorTree.traverse(this.anchorTree.root, findMinZ);
    return minZ;
  }

  public moveElement(element: FluidElement, direction: number){

    const elementNode = this.anchorTree.findValue(this.anchorTree.root!, element);
    if (elementNode===null) throw new Error("FluidContainer.moveElementForwards(): elementNode is null");

    const edge = direction >= 0 ? this.getMaxZ() : this.getMinZ();
    if (element.spatial.z === edge){
      return;
    }

    const deltaZ = direction >= 0 ? 1 : -1;
    const currentZ = elementNode.getContent().spatial.z;
    const targetZ = currentZ + deltaZ;

    const updateZ = (childNode: AnchorNode, parentNode: AnchorNode): boolean=>{
      if (childNode === elementNode){
        element.spatial.z = targetZ;
      } else if (childNode.getContent().spatial.z === targetZ){
        childNode.getContent().spatial.z = currentZ;
      }
      // console.log(childNode.getContent().spatial.z);
      return false;
    };

    this.anchorTree.traverse(this.anchorTree.root, updateZ);
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}