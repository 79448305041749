
import MixinProps from '@/components/shared/view/MixinProps';
import { ref, Ref, watch, reactive, computed } from 'vue';
import Context, { Mode } from '@/components/shared/view/Context';
import ContentEditor from '@/components/shared/util/ContentEditor.vue';
import StyleEditor from '@/components/shared/util/StyleEditor.vue';
import ThemeEditor from '@/components/shared/util/ThemeEditor.vue';
import PageManager from '@/components/shared/util/PageManager.vue';
import ItemSelector from "@/components/shared/util/ItemSelector.vue";
import BlockSelector from "@/components/shared/util/BlockSelector.vue";
import { Language } from '@/components/shared/model/BaseVars';
import { useServerDelegate } from '@/shared/UseServerDelegate';
import isEqual from 'lodash/isEqual';
import { AnchorPosition } from '@/components/elemental/fluidContainer/view/shared/FluidElement';

const getObjectDelta = (obj1: { [key: string]: any }, obj2: { [key: string]: any }): { [key: string]: any } => {
  const delta: { [key: string]: any } = {};

  // Helper function to compare nested objects
  const compareObjects = (o1: { [key: string]: any }, o2: { [key: string]: any }, baseKey: string = '') => {
    for (const key in o1) {
      if (Object.hasOwn(o1, key)) {
        const fullKey = baseKey ? `${baseKey}.${key}` : key;
        if (o1[key] && typeof o1[key] === 'object' && o2[key] && typeof o2[key] === 'object') {
          compareObjects(o1[key], o2[key], fullKey);
        } else if (o1[key] !== o2[key]) {
          delta[fullKey] = { oldValue: o1[key], newValue: o2[key] };
        }
      }
    }

    // Check for properties in o2 that are not in o1
    for (const key in o2) {
      if (Object.hasOwn(o2, key) && !Object.hasOwn(o1, key)) {
        const fullKey = baseKey ? `${baseKey}.${key}` : key;
        delta[fullKey] = { oldValue: undefined, newValue: o2[key] };
      }
    }
  };

  compareObjects(obj1, obj2);

  return delta;
};

export default {
  mixins: [MixinProps],
  name: "AppEditorDrawer",
  components: {
    ContentEditor,
    StyleEditor,
    ThemeEditor,
    PageManager,
    ItemSelector,
    BlockSelector
  },
  setup(props: any) {
    const refContext = props.context as Context;

    const refContentEditorContext = reactive(new Context());
    refContentEditorContext.from(refContext);
    refContentEditorContext.mode = new Mode(true, false, false, false);

    watch(
      ()=>[refContext.language, refContext.selection.app!.theme.paletteNameActive],
      ()=>{
        refContentEditorContext.language = refContext.language;
        refContentEditorContext.selection.app!.theme.paletteNameActive = refContext.selection.app!.theme.paletteNameActive;
      }
    );
    //////////////////////////////////////////////////////////////////
    const refEditType = ref("None");
    const refDrawerState = ref("Closed") as Ref<string>;
    const refHeight = ref(40) as Ref<number>;
    //////////////////////////////////////////////////////////////////
    const openDrawer = (height = 350)=>{
      refDrawerState.value = "Opened";
      refHeight.value = height;
    }

    const closeDrawer = ()=>{
      refDrawerState.value = "Closed";
      refHeight.value = 40;
    }

    const hideDrawer = ()=>{
      refDrawerState.value = "Hidden";
      refHeight.value = 40;
    }
    //////////////////////////////////////////////////////////////////
    //TODO: State machine should be handled in AppEditor, within a
    //      component which is always alive.
    watch(
      () => [refContext.mode, refContext.selection.item],
      () => {
        const ifEditing = refContext.mode.selectItem || refContext.mode.editLayout || refContext.mode.editTextInline;
        const selectedItemNull = refContext.selection.item == null;
        switch (refDrawerState.value){
          case "Opened":
            if (!ifEditing || selectedItemNull){
              hideDrawer(); //TODO: should go through Context.uiState
            }
            break;
          case "Closed":
            break;
          case "Hidden":
            if (ifEditing && refEditType.value!=='AddItem' && refEditType.value!=='AddBlock'){
              refContext.uiState.requestEditType(refEditType.value);
            }
        }
      },
      {deep: true}
    );

    watch(
      () => refContext.uiState.getLastRequestEditType(),
      () => {
        const editType = refContext.uiState.getLastRequestEditType().type;
        if (editType === "Theme"){
          refEditType.value = editType;
          openDrawer();
          return;
        }
        if (editType === "Pages"){
          refEditType.value = editType;
          openDrawer();
          return;
        }
        if (refContext.selection.item != null){
          refEditType.value = editType;
          openDrawer();
        } else {
          closeDrawer();
        }
        if (editType === 'None'){
          refEditType.value = editType;
          closeDrawer();
        }
      },
    );

    watch(
      () => refContext.uiState.getLastRequestEditFluid(),
      () => {
        if (refContext.uiState.getLastRequestEditFluid().container && refContext.uiState.getLastRequestEditFluid().element){
          refEditType.value = "Fluid";
          closeDrawer();
        }
      },
    );

    watch(
      () => refContext.selection.item,
      () => {
        if (refEditType.value === "Fluid"){
          refContext.uiState.requestEditType('None');
          refContext.uiState.requestEditFluid(undefined, undefined);
        }
      },
    );

    watch(
      () => refContext.uiState.getLastRequestAddItem(),
      () => {
        if (refContext.uiState.getLastRequestAddItem().type === 'item'){
          refEditType.value = "AddItem";
          openDrawer();
        } else if (refContext.uiState.getLastRequestAddItem().type === 'block'){
          refEditType.value = "AddBlock";
          openDrawer(window.innerHeight);
        }
      },
    );

    let lastAppStoreObject = refContext.selection.app?.getStoreObject();
    let refAppStoreObjectChanged = ref(false);
    watch(
      ()=>[refContext.selection.app],
      (newValue, oldValue)=>{
        if(!refContext.selection.app) return;

        // initialize lastAppStoreObject
        if (lastAppStoreObject === undefined){
          console.log("lastAppStoreObject initialized");
          lastAppStoreObject = refContext.selection.app.getStoreObject();
        }

        // re-initialize lastAppStoreObject if app changed.
        if (newValue[0]!==undefined && oldValue[0]!==undefined){
          if (newValue[0].ref !== oldValue[0].ref) {
            console.log("lastAppStoreObject re-initialized");
            lastAppStoreObject = refContext.selection.app.getStoreObject();
          }
        }

        if(newValue[0] != null && oldValue[0] != null){
          const newAppStoreObject = newValue[0]?.getStoreObject();
          if (!isEqual(lastAppStoreObject, newAppStoreObject) ){
            const delta = getObjectDelta(lastAppStoreObject, newAppStoreObject);
            console.log("💾 App store object changed", delta);
            lastAppStoreObject = newAppStoreObject;
            refAppStoreObjectChanged.value = true;
          }
        }
      },
      {deep: true}
    );

    let refPageStoreObjectChanged = ref(false);
    watch(
      ()=>[refContext.selection.page, refContext.remotePageStoreObject],
      ()=>{
        if(!refContext.selection.page || !refContext.remotePageStoreObject) return;

        const newPageStoreObject = refContext.selection.page?.getStoreObject();

        if (!isEqual(refContext.remotePageStoreObject, newPageStoreObject) ){
          const delta = getObjectDelta(refContext.remotePageStoreObject, newPageStoreObject);
          console.log("💾 Page store object changed", delta);
          refPageStoreObjectChanged.value = true;
        }
      },
      {deep: true}
    );

    const toggleEditMode = ()=>{
      refContext.mode.selectItem=!refContext.mode.selectItem;
      refContext.mode.editLayout=!refContext.mode.editLayout;
      refContext.mode.editTextInline=!refContext.mode.editTextInline;
    };

    const toggleLanguage = ()=>{
      if (refContext.language === Language.en){
        refContext.setLanguage(Language.zh);
      }
      else{
        refContext.setLanguage(Language.en);
      }
    };

    const {updateApp, updatePage} = useServerDelegate();
    const save = ()=>{
      // App
      if(refContext.selection.app){
        updateApp(
          refContext.selection.app!.ref,
          JSON.stringify(refContext.selection.app!.getStoreObject()),
          (value)=>{
            refAppStoreObjectChanged.value = false;
            console.log('App save success:', value);
          },
          (error)=>{
            console.error('App save error:', error);
          }
        );
      }
      // Page
      if (refContext.selection.pageRef){
        const storeObject = refContext.selection.page!.getStoreObject()
        updatePage(
          refContext.selection.pageRef!.refId,
          JSON.stringify(storeObject),
          (value)=>{
            refPageStoreObjectChanged.value = false;
            refContext.remotePageStoreObject = storeObject;
            console.log('Page save success:', value);
          },
          (error)=>{
            console.error('Page save error:', error);
          }
        );
      } else {
        throw new Error("AppEditorDrawer:save(): no page ref id")
      }
    };

    const computedInEditMode = computed(()=>{
      return refContext.mode.selectItem || refContext.mode.editLayout || refContext.mode.editTextInline;
    });

    //////////////////////////////////////////////////////////////////
    return {
      props,
      refContext,
      refContentEditorContext,

      refHeight,
      refDrawerState,
      refEditType,

      refAppStoreObjectChanged,
      refPageStoreObjectChanged,

      toggleEditMode,
      toggleLanguage,
      save,

      computedInEditMode,

      AnchorPosition
    }
  }
}
