
import {createRouter, createWebHistory, useRoute} from 'vue-router'

import AppEditorDelegate from './appEditor/view/AppEditorDelegate.vue';

const routes = [
  // {
  //   path: '/',
  //   component: AppEditorDelegate
  // },
  // {
  //   path: '/:pageId',
  //   component: AppEditorDelegate
  // },
  {
    path: '/:userId/:appId/:pageId',
    component: AppEditorDelegate
  },
  {
    path: '/:userId/:appId',
    component: AppEditorDelegate
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;

export const getPathForPage = (pageId: string) : string => {
  // Internal link starts with '/'
  const n = pageId.lastIndexOf('/');
  pageId = pageId.substring(n + 1);

  const params = useRoute().params;
  return `/${params.userId}/${params.appId}/${pageId}`;
}