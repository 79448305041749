
import MixinProps from '@/components/shared/view/MixinProps';
import { Ref, toRef } from 'vue';
import Context from '@/components/shared/view/Context';
import PropertyEditor from "@/components/shared/util/PropertyEditor.vue";
import { ImageGallery } from '../../ImageGallery';
import { VueDraggable } from 'vue-draggable-plus'

export default {
  mixins: [MixinProps],
  name: 'ButtonEditor',
  components: {PropertyEditor, VueDraggable},
  setup(props: any){
    const refImageGallery = toRef(props, 'item') as Ref<ImageGallery>;
    const refContext = toRef(props, 'context') as Ref<Context>;

    return {
      refImageGallery,
      refContext,
    }
  }
}
