import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import Style from "./view/default/Style";
import ContainerDefaultStyle from "@/components/elemental/container/view/default/Style";
import { Text } from "@/components/elemental/text/Text";
import StyleText from "@/components/elemental/text/view/default/Style";
import { Container } from "@/components/elemental/container/Container";
import { FluidContainer} from "@/components/elemental/fluidContainer/FluidContainer";
import { Video } from "@/components/elemental/video/Video";
import { PaletteColor } from "@/components/shared/theme/PaletteSet";
import { Language } from "@/components/shared/model/BaseVars";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";
import { Rect, Spatial } from "@/components/elemental/fluidContainer/view/shared/FluidElement";

export
class BlockBannerVideo extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "BlockBannerVideo", friendlyName: "Video Banner Block"};

  static readonly nameContainer: string = "Container";

  container = new Container(BlockBannerVideo.nameContainer);

  public getStoreObjectItem(): Object{
    return {
      container: this.container.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.container.fromStoreObject(item.container);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
    const containerStyle = new ContainerDefaultStyle();
    containerStyle.contained.contentMaxWidth.setValue('');
    containerStyle.contained.color.setStyleVariantName(PaletteColor.B1);
    this.container.setStyle(containerStyle);
    this.container.content = new FluidContainer(Container.nameContent);
    const videoRect = new Rect(0,0,1,496);
    (this.container.content as FluidContainer).addElementToAnchorTree(new Spatial(videoRect.clone(), videoRect.clone()), new Video());
    const text = new Text(Container.nameContent);
    text.setValueOf(Language.en, '<h1 style="text-align: center;"><span>Design</span></h1>');
    const styleText = new StyleText();
    styleText.font.color.setStyleVariantName(PaletteColor.F1);
    text.setStyle(styleText);
    const textRect = new Rect(0,192,1,96);
    (this.container.content as FluidContainer).addElementToAnchorTree(new Spatial(textRect.clone(), textRect.clone()), text);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const blockBannerVideo = item as BlockBannerVideo;
    this.container.from(blockBannerVideo.container);
  }

  getMeta(): MetaInfo{
    return BlockBannerVideo.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new BlockBannerVideo();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}