
import { Theme } from '../theme/Theme';
import { Predefined, ThemeParam } from '@/components/shared/theme/Predefined';
import { PropType } from 'vue';
import Context from '@/components/shared/view/Context';
import { Button } from '@/components/elemental/button/Button';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { BaseStyle } from '../model/BaseStyle';
import { App } from '@/components/app/app/App';

export default {
  name: 'ThemeSelector',
  components: {
    ComponentWrapper
  },
  props: {
    theme: {
      type: Object as PropType<Theme>,
      required: true,
    },
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
  },
  setup(props: any){
    const refTheme = props.theme as Theme;
    const refContext = props.context as Context;

    const getButtonWithStyle = (style: BaseStyle): Button=>{
      const button = new Button;
      button.title.setValueOf(refContext.defaultLanguage, 'Select');
      button.setStyle(style);
      return button;
    }

    const getContextWithTheme = (themeParam: ThemeParam): Context=>{
      const context = new Context;
      context.from(refContext);
      context.selection.app = new App();
      context.selection.app!.theme.initWith(themeParam);
      return context;
    }

    return{
      refTheme,
      refContext,
      Predefined,
      Button,
      getButtonWithStyle,
      getContextWithTheme
    }
  },
}
