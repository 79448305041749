
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { GoogleMap, Marker } from 'vue3-google-map'
import { Ref, onMounted, toRef } from 'vue';
import {Location} from "../../Location";

export default {
  name: 'LocationDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components: { GoogleMap, Marker, ComponentContainer },
  setup(props: any, context: any){
    const refLocation = toRef(props, 'item') as Ref<Location>;

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refLocation.value, context);
    onMounted(()=>{
      emitLayoutInitialized();
    });

    return{
      props,
      refLocation,
    }
  }
}
