import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import { BaseItem } from "@/components/shared/model/BaseItem";
import { Location } from '../../Location';

export default function useLocation(item: BaseItem) {
  const getLong = (): PropertyString => {
    return (item as Location).long;
  };

  const getLat = (): PropertyString => {
    return (item as Location).lat;
  };

  return {
    getLong,
    getLat,
  };
}