import { Tree, TreeNode } from "./Tree";
import { AnchorPosition, FluidElement, Rect, Spatial } from "../shared/FluidElement";

export class AnchorNode extends TreeNode<FluidElement>{
  clone(): AnchorNode {
    const newNode = super.clone() as AnchorNode;
    newNode.content = this.getContent().clone() as FluidElement;
    return newNode;
  }

  static deserializeNode (nodeObject: any): AnchorNode{
    let content = null as FluidElement | null;
    if (nodeObject.content !== null && nodeObject.content !== undefined){
      content = new FluidElement();
      content.fromStoreObject(nodeObject.content);
    }
    const node = new AnchorNode(content);
    node.children = nodeObject.children.map((childObject: any) => {
      const childNode =AnchorNode.deserializeNode(childObject);
      childNode.parent = node;
      return childNode;
    });
    return node;
  }
}

/*
----------------------------------------------------------------
     Root (Container)
     /|\
    a b c    <- Level 1 elements will get pushed "generically"
   /\    \
  d  e    f  <- Level 2, 3, ... elements follow their targets/parents
----------------------------------------------------------------
*/
export
class AnchorTree extends Tree<FluidElement>{
  // A 2D representation of the tree. The root is not in there.
  elementNodeArray = [] as AnchorNode[];

  constructor(){
    super();
    this.init();
  }

  getStoreObject(): Object | null{
    const serializeNode = (node: AnchorNode): Object => {
      return {
        content: node.content ? node.content.getStoreObject() : null,
        children: node.children.map(serializeNode)
      };
    };

    const object = {
      root: this.root ? serializeNode(this.root) : null
    };
    return object
  }

  public fromStoreObject(object: any){
    if (object===undefined) {
      this.root = null;
      return;
    }
    const rootNodeObject = object.root;

    this.root = rootNodeObject ? AnchorNode.deserializeNode(rootNodeObject) : null;
    this.updateElementArray();
  }

  init(){
    this.root = new AnchorNode(null); // The root is always anchor null
    this.elementNodeArray = [];
  }

  updateElementArray(){
    console.log("AnchorTree.updateElementArray() called");
    this.elementNodeArray = [];

    this.traverse(
      this.root,
      (child: AnchorNode , parent: AnchorNode):boolean => {
        if(child.content!==null){
          this.elementNodeArray.push(child);
        }
        return false;
      }
    );
  }

  addElement(element: FluidElement): AnchorNode{
    // 1. Change source and target anchor positions to None
    element.spatial.sourceAnchorVerticalPosition = AnchorPosition.None;
    element.spatial.targetAnchorVerticalPosition = AnchorPosition.None;
    // 2. Add to the root
    const elementNode = new AnchorNode(element);
    elementNode.parent = this.root;
    this.root!.children.push(elementNode);
    return elementNode;
  }

  removeElement(element: FluidElement){
    // 1. For every child, change source and target anchor positions to None
    const elementNode = this.findValue(this.root!, element);
    if (!elementNode) throw new Error("AnchorTree: removeElement: element not found");
    for (const child of elementNode.children){
      child.getContent().spatial.sourceAnchorVerticalPosition = AnchorPosition.None;
      child.getContent().spatial.targetAnchorVerticalPosition = AnchorPosition.None;
    }
    // 2. Add children to the root
    this.root!.children.push(...elementNode.children);
    // 3. Remove source from the target
    this.remove(element);
  }

  link(sourceNode: AnchorNode, targetNode: AnchorNode, sourcePosition: AnchorPosition, targetPosition: AnchorPosition){
    // 1. Move source to the new target
    // 2. Change source and target position
    if (sourceNode === targetNode) throw new Error("AnchorTree:link(): sourceNode === targetNode");

    this.moveNode(sourceNode, targetNode);
    sourceNode.getContent().spatial.sourceAnchorVerticalPosition = sourcePosition;
    sourceNode.getContent().spatial.targetAnchorVerticalPosition = targetPosition;
    this.updateElementArray();
  }

  unlink(sourceNode: AnchorNode){
    // 1. Remove source from the target
    // 2. Add source to the children of the root
    // 3. Change source and target anchor positions to None
    this.link(sourceNode, this.root!, AnchorPosition.None, AnchorPosition.None);
    this.updateElementArray();
  }

  ifLinkable(sourceNode: AnchorNode, targetNode: AnchorNode){
    // Unlinkable targets are those in the sub-tree of source, including source
    if (sourceNode === targetNode) return false;
    return this.findNode(sourceNode, targetNode) === null;
  }

  updateSourceDisplayedY(sourceNode: AnchorNode, targetNode: AnchorNode): boolean{
    // 1. Check if parent is the root, if yes, do nothing
    if (sourceNode.getContent().spatial.sourceAnchorVerticalPosition == AnchorPosition.None || sourceNode.getContent().spatial.targetAnchorVerticalPosition == AnchorPosition.None){
      sourceNode.getContent().spatial.displayed.y = sourceNode.getContent().spatial.desired.y;
      return false;
    }

    // 2. Calculate y based on parent y and anchor positions
    const sourceSpatial = sourceNode.getContent().spatial;
    let displayedSourceAnchorOffset = 0;
    let desiredSourceAnchorY = 0;
    switch(sourceSpatial.sourceAnchorVerticalPosition){
      case AnchorPosition.Top:
        displayedSourceAnchorOffset = 0;
        desiredSourceAnchorY = sourceSpatial.desired.y;
        break;
      case AnchorPosition.Center:
        displayedSourceAnchorOffset = -sourceSpatial.displayed.height/2;
        desiredSourceAnchorY = sourceSpatial.desired.y + sourceSpatial.desired.height/2;
        break;
      case AnchorPosition.Bottom:
        displayedSourceAnchorOffset = -sourceSpatial.displayed.height;
        desiredSourceAnchorY = sourceSpatial.desired.y + sourceSpatial.desired.height;
        break;
    }
    const targetSpatial = targetNode.getContent().spatial;
    let displayedTargetAnchorOffset = 0;
    let desiredTargetAnchorY = 0;
    switch(sourceSpatial.targetAnchorVerticalPosition){
      case AnchorPosition.Top:
        displayedTargetAnchorOffset = 0;
        desiredTargetAnchorY = targetSpatial.desired.y;
        break;
      case AnchorPosition.Center:
        displayedTargetAnchorOffset = targetSpatial.displayed.height/2;
        desiredTargetAnchorY = targetSpatial.desired.y + targetSpatial.desired.height/2;
        break;
      case AnchorPosition.Bottom:
        displayedTargetAnchorOffset = targetSpatial.displayed.height;
        desiredTargetAnchorY = targetSpatial.desired.y + targetSpatial.desired.height;
        break;
    }
    const offset = displayedSourceAnchorOffset + displayedTargetAnchorOffset;
    const gap = desiredSourceAnchorY - desiredTargetAnchorY;
    // console.log(`displayedSourceAnchorOffset = ${displayedSourceAnchorOffset}, displayedTargetAnchorOffset = ${displayedTargetAnchorOffset}, gap = ${gap}`);
    sourceNode.getContent().spatial.displayed.y = targetNode.getContent().spatial.displayed.y + offset + gap;
    return false;
  }

  updateAllDisplayedY(){
    // Traverse the tree and update y
    this.traverse(
      this.root,
      this.updateSourceDisplayedY
    );
  }

  printDebugInfo(){
    console.log(this);
  }
}