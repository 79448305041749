import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import Style from "./view/default/Style";
import ContainerDefaultStyle from "@/components/elemental/container/view/default/Style";
import { Layout } from "@/components/elemental/layout/Layout";
import { Image } from "@/components/elemental/image/Image";
import { Text } from "@/components/elemental/text/Text";
import { Container } from "@/components/elemental/container/Container";
import { FluidContainer} from "@/components/elemental/fluidContainer/FluidContainer";
import { Button } from "@/components/elemental/button/Button";
import StyleButton from "@/components/elemental/button/view/default/Style";
import { Spacer } from "@/components/elemental/spacer/Spacer";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";
import { Rect, Spatial } from "@/components/elemental/fluidContainer/view/shared/FluidElement";

export
class BlockText extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "BlockText", friendlyName: "Text Block"};

  static readonly nameContainer: string = "Container";

  container = new Container(BlockText.nameContainer);

  public getStoreObjectItem(): Object{
    return {
      container: this.container.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.container.fromStoreObject(item.container);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    const layout = new Layout("Layout");
    const left = new FluidContainer;
    const imageRect = new Rect(1/12,48,10/12,288);
    left.addElementToAnchorTree(new Spatial(imageRect.clone(), imageRect.clone()), new Image());
    const right = new FluidContainer;
    const rightText = new Text();
    const textRect = new Rect(1/12,48,10/12,288);
    right.addElementToAnchorTree(new Spatial(textRect.clone(), textRect.clone()), rightText);
    const button = new Button();
    button.setStyle(new StyleButton());
    button.setStyleVariantName("Primary");
    const buttonRect = new Rect(4/12,400,4/12,48);
    right.addElementToAnchorTree(new Spatial(buttonRect.clone(), buttonRect.clone()), button);
    const spacerRect = new Rect(4/12,448,4/12,48);
    right.addElementToAnchorTree(new Spatial(spacerRect.clone(), spacerRect.clone()), new Spacer);
    layout.content = left;
    layout.morphToRowAndAddChild(right);

    this.container.content = layout;
    const containerStyle = new ContainerDefaultStyle;
    containerStyle.contained.backgroundMode.setEnum("Color");
    containerStyle.contained.color.setStyleVariantName("B1");
    this.container.setStyle(containerStyle);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const blockText = item as BlockText;
    this.container.from(blockText.container);
  }

  getMeta(): MetaInfo{
    return BlockText.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new BlockText();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}