
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import Contained from '@/components/shared/util/Contained.vue';
import { Ref, computed, onMounted, toRef } from 'vue';
import useStyle from '@/components/shared/style/useStyle';
import { Button } from '../../Button';
import StyleButton from './Style';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import StyleContained from '@/components/shared/style/StyleContained';
import { getPathForPage } from '@/Router';

export default {
  name: 'ButtonViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
    Contained,
  },
  setup(props: any, context: any){
    const { getRefItem, getRefContext, getRefStyle } = useAddReactivity();
    const refButton = getRefItem<Button>(props);
    const refContext = getRefContext(props);
    const refStyleButton = getRefStyle<StyleButton>(props);
    const refStyleContained = toRef(refStyleButton.value, 'contained') as Ref<StyleContained>;
    const computedIfInternal = computed(()=>{
      return refButton.value.url.getValueOf(refContext.value.language).startsWith("/");
    });
    const { getClassNames } = useStyle(refButton.value, refContext.value); //BROKEN

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refButton.value, context);

    onMounted(()=>{
      emitLayoutInitialized();
    });

    const getButtonStyle = ()=>{
      return {
        ...refStyleButton.value.getPredefinedFontStyleObject(refContext.value),
      };
    }

    return {
      refButton,
      refContext,
      refStyleButton,
      refStyleContained,
      computedIfInternal,
      getClassNames,
      getButtonStyle,

      getPathForPage
    };
  },
}
