
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { onMounted, Ref, toRef } from 'vue';
import { Video } from '../../Video';

// variable
export default {
  name: 'VideoDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
  },
  setup(props: any, context: any){
    const refVideo = toRef(props, 'item') as Ref<Video>;

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refVideo.value, context);

    onMounted(()=>{
      emitLayoutInitialized();
    })

    return {
      props,
      refVideo,
      emitLayoutInitialized,
    };
  }
}
