import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from '@aws-amplify/auth';
import { JWT, signIn, type SignInInput, signOut } from 'aws-amplify/auth';

const region = "eu-north-1";
const userPoolId = "eu-north-1_t4SIAnJsr";
const clientId = "57bkvmllt9ohoht63q413vtt7p"; // Cognito - App integration - App client list
Amplify.configure({
  aws_project_region: region,
  // "aws_cognito_identity_pool_id": userPoolId,
  // "aws_cognito_region": region,
  aws_user_pools_id: userPoolId,
  aws_user_pools_web_client_id: clientId,
  oauth: {},
} as any);

export function useServerDelegate() {
  // https://docs.amplify.aws/gen1/vue/build-a-backend/auth/enable-sign-up/
  async function userSignIn({ username, password }: SignInInput, onFinished: ()=>void) {
    await userSignOut(()=>{});
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      isSignedIn;
      nextStep;
      onFinished();
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  async function userSignOut(onFinished: ()=>void) {
    try {
      await signOut();
      onFinished();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  async function getToken() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      idToken;
      console.log('getToken:', accessToken?.payload);
      return accessToken;
    } catch (err) {
      console.log(err);
      throw err; // Ensure that the error is thrown so that it can be handled by the caller
    }
  }

  const postUserDoc = async (apiUrl: string, value: string, token: JWT, onFinished: (value: Object) => void, onError: (error: any) => void) => {
    fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: value
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const json = response.json();
        onFinished(json);
        return json; // Parse the response body as JSON
      })
      // .then(data => {
      //   onFinished(data);
      // })
      .catch(error => {
        onError(error);
      }
    );
  };
  const getUserDoc = (apiUrl: string, onFinished: (value: Object) => void, onError: (error: any) => void) => {
    fetch(apiUrl, { cache: "no-store" }).then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    }).then(data => {
      onFinished(data);
    }).catch(error => {
      if (onError){
        onError(error);
      }
      return null;
    });
  }

  const updateApp = async (docId: string, value: string, onFinished: (value: Object) => void, onError: (error: any) => void) => {
    const token = await getToken();
    if (!token) throw new Error("updateApp: no valid token");

    const apiUrl = `https://mobu.app:3000/api/v1/user/${token.payload.sub}/doc/app/${docId}`;
    postUserDoc(apiUrl, value, token, onFinished, onError);
  };
  const getApp = (userId: string, docId: string, onFinished: (value: Object) => void, onError: (error: any) => void) => {
    const apiUrl = `https://mobu-user-doc.s3.eu-north-1.amazonaws.com/${userId}/app/${docId}.json`;
    getUserDoc(apiUrl, onFinished, onError);
  }

  const createPage = async (docId: string, value: string, onFinished: (value: Object) => void, onError: (error: any) => void) => {
    const token = await getToken();
    if (!token) throw new Error("createPage: no valid token");

    const apiUrl = `https://mobu.app:3000/api/v1/user/${token.payload.sub}/doc/page/${docId}`;
    postUserDoc(apiUrl, value, token, onFinished, onError);
  };
  const updatePage = async (docId: string, value: string, onFinished: (value: Object) => void, onError: (error: any) => void) => {
    const token = await getToken();
    if (!token) throw new Error("updatePage: no valid token");

    const apiUrl = `https://mobu.app:3000/api/v1/user/${token.payload.sub}/doc/page/${docId}`;
    postUserDoc(apiUrl, value, token, onFinished, onError);
  };
  const getPage = (userId: string, docId: string, onFinished: (value: Object) => void, onError: (error: any) => void) => {
    const apiUrl = `https://mobu-user-doc.s3.eu-north-1.amazonaws.com/${userId}/page/${docId}.json`;
    getUserDoc(apiUrl, onFinished, onError);
  }
  const listPages = (onFinished: (pageList: string[]) => void, onError: (error: any) => void) => {
    onFinished(["0.json"]);
  }

  return {
    userSignIn,
    userSignOut,
    getToken,

    updateApp,
    getApp,

    createPage,
    updatePage,
    getPage,
    listPages
  };
}