import { BaseItem } from "./BaseItem";

export abstract class BaseVar extends BaseItem{
  public value: string = ""; // must be public for v-model as LHS

  public getStoreObjectItem(): Object{
    return {
      value: this.value
    };
  }

  public fromStoreObjectItem(item: any){
    if (item)
      this.value = item.value;
  }

  public setValue(value: string): void{
    this.value = value;
  }

  public getValue(): string{
    return this.value;
  }
}