import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import { Collection } from "../../elemental/collection/Collection";
import { Image } from "../../elemental/image/Image";
import Style from "./view/default/Style";
import ImageCollectionStyle from "@/components/elemental/collection/view/grid/Style"
import { Text } from "@/components/elemental/text/Text";
import StyleImageNone from "@/components/elemental/image/view/none/Style";
import { Language } from "@/components/shared/model/BaseVars";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class Product extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Product", friendlyName: "Product"};

  static readonly nameTitle: string = "Title";
  static readonly namePrice: string = "Price";
  static readonly nameDescription: string = "Description";
  static readonly nameThumbnail: string = "Thumbnail";
  static readonly nameImages: string = "Images";

  title = new PropertyString(Product.nameTitle);
  price = new PropertyString(Product.namePrice);
  description = new Text(Product.nameDescription);
  thumbnail = new Image(Product.nameThumbnail);
  images = new Collection(Product.nameImages, [Image]);

  public getStoreObjectItem(): Object{
    return {
      title: this.title.getStoreObject(),
      price: this.price.getStoreObject(),
      description: this.description.getStoreObject(),
      thumbnail: this.thumbnail.getStoreObject(),
      images: this.images.getStoreObject(),
    };
  }

  public fromStoreObjectItem(item: any){
    this.title.fromStoreObject(item.title);
    this.price.fromStoreObject(item.price);
    this.description.fromStoreObject(item.description);
    this.thumbnail.fromStoreObject(item.thumbnail);
    this.images.fromStoreObject(item.images);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
    this.title.setValueOf(Language.en, "Product Title");
    this.price.setValueOf(Language.en, "99.99");
    this.description.setValueOf(Language.en, "Product Title");
    this.thumbnail.styleVariantName = "";
    this.thumbnail.setStyle(new StyleImageNone);

    const Image1 = new Image("");
    this.images.addNewElement(Image1);
    const Image2 = new Image("");
    this.images.addNewElement(Image2);
    const Image3 = new Image("");
    this.images.addNewElement(Image3);
    this.images.setStyle(new ImageCollectionStyle);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const product = item as Product;
    this.title.from(product.title);
    this.price.from(product.price);
    this.thumbnail.from(product.thumbnail);
    this.images.from(product.images);
  }

  getMeta(): MetaInfo{
    return Product.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Product();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}