import { Ref, reactive, ref, toRef, watch } from "vue"
import { BaseVars, Language } from "../model/BaseVars";
import { useAddReactivity } from "./AddReactivity";

export function useRefVarsAndCurrentValue() {
  const getRefVarsAndCurrentValue = <T extends BaseVars>(props: any): [Ref<T>, Ref<string>] => {
    const { getRefItem } = useAddReactivity();
    const refVars = getRefItem<T>(props);
    const refCurrentLanguage = toRef(props.context, "language") as Ref<Language>;
    const refCurrentValue = ref(props.item.getValueOf(props.context.language)) as Ref<string>;

    watch([refVars, refCurrentLanguage], () =>
      {
        refCurrentValue.value = refVars.value.getValueOf(refCurrentLanguage.value);
      },
      {deep: true}
    );
    watch(refCurrentValue, () =>
      {
        refVars.value.setValueOf(refCurrentLanguage.value, refCurrentValue.value);
      }
    )

    return [refVars, refCurrentValue];
  }

  return {
    getRefVarsAndCurrentValue
  };
}