import { BaseItem } from "./BaseItem";

export enum Language{
  default = "en",
  en = "en",
  zh = "zh"
}

export abstract class BaseVars extends BaseItem{
  defaultLanguage = Language.default;
  values = new Map<string, string>([[this.defaultLanguage, "Empty"]]);

  public getStoreObject(): Object{
    return {
      ...super.getStoreObject(),
      item: {
        defaultLanguage: this.defaultLanguage,
        values: Object.fromEntries(this.values),
        ...this.getStoreObjectItem()
      },
    };
  }

  public fromStoreObject(object: any){
    super.fromStoreObject(object);
    const item = object.item;
    this.defaultLanguage = item.defaultLanguage;
    this.values = new Map(Object.entries(item.values));
  }

  constructor (){
    super();
  }

  public getValueOf(language: Language): string {
    const value = this.values.get(language);
    if (value == undefined)
      return this.values.get(this.defaultLanguage)!;
    return value;
  }

  public setValueOf(language: Language, value: string) {
    return this.values.set(language, value);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    this.values = new Map((item as BaseVars).values);
  }
}