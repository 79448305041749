import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54b06f08"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Contained = _resolveComponent("Contained")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refButton,
    context: $setup.refContext
  }, {
    body: _withCtx(() => [
      _createVNode(_component_Contained, {
        styleContained: $setup.refStyleContained,
        context: $setup.refContext
      }, {
        body: _withCtx(() => [
          ($setup.refContext.mode.editContent || $setup.refContext.mode.editLayout || $setup.refContext.mode.editTextInline)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: _normalizeClass([$setup.getClassNames().button, "Button InheritSize"]),
                style: _normalizeStyle($setup.getButtonStyle())
              }, _toDisplayString($setup.refButton.title.getValueOf($setup.refContext.language)), 7))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                ($setup.computedIfInternal)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: _normalizeClass([$setup.getClassNames().button, "Button InheritSize"]),
                      to: {path: $setup.getPathForPage($setup.refButton.url.getValueOf($setup.refContext.language)), query: _ctx.$route.query},
                      style: _normalizeStyle($setup.getButtonStyle())
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.refButton.title.getValueOf($setup.refContext.language)), 1)
                      ]),
                      _: 1
                    }, 8, ["class", "to", "style"]))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      class: _normalizeClass([$setup.getClassNames().button, "Button InheritSize"]),
                      href: $setup.refButton.url.getValueOf($setup.refContext.language),
                      style: _normalizeStyle($setup.getButtonStyle())
                    }, _toDisplayString($setup.refButton.title.getValueOf($setup.refContext.language)), 15, _hoisted_1))
              ], 64))
        ]),
        _: 1
      }, 8, ["styleContained", "context"])
    ]),
    _: 1
  }, 8, ["item", "context"]))
}