import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import StylePadding from "@/components/shared/style/StylePadding"
import StyleContained from "@/components/shared/style/StyleContained";
import { EnumPair, StyleVariableString } from "@/components/shared/style/StyleVariableString";
import Context from "@/components/shared/view/Context";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Image Style"};

  static readonly nameObjectFit: string = "Object Fit";
  // static readonly nameObjectPosition: string = "Object Position";

  public objectFit = new StyleVariableString(Style.nameObjectFit, "Cover");
  // public objectPosition = new StyleVariableString(Style.nameObjectPosition, "50% 50%");
  public contained = new StyleContained;

  public getStoreObjectItem(): Object{
    return {
      objectFit: this.objectFit.getStoreObject(),
      contained: this.contained.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.objectFit.fromStoreObject(item.objectFit);
    this.contained.fromStoreObject(item.contained);
  }

  getClassNames(): any{
    // TODO: complete css class names here and in the Viewer
    return {
    }
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");

    this.objectFit.enums = [
      new EnumPair("Cover", "Cover"),
      new EnumPair("Contain", "Contain")
    ];
    this.objectFit.enumOnly = true;

    this.contained.maxWidth.enums = [new EnumPair("S", "200px"), new EnumPair("M", "400px"), new EnumPair("L", "600px")];
  }

  from(item: BaseItem): void{
    super.from(item);
    this.setStyleName((item as BaseStyle).getStyleName());
    // this.objectPosition.from((item as Style).objectPosition);
    this.objectFit.from((item as Style).objectFit);
    this.contained.from((item as Style).contained);
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  getPredefinedStyleObject(context: Context) {
    throw new Error("Image default style getPredefinedStyleObject() not implemented");
  }
}