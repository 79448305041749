
import { computed, onMounted, PropType, reactive, ref, watch } from 'vue';
import BlockContainer from '@/components/shared/util/BlockContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import Draggable from './Draggable.vue' // TODO: Unify with src/components/composed/imageWithText/view/shared/Draggable.vue
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, { useSerialLayoutInitialization } from '@/components/shared/view/SerialLayoutInitialization';
import { BaseItem } from '@/components/shared/model/BaseItem';
import { FluidContainer } from '../../FluidContainer';
import Context from '@/components/shared/view/Context';
import useUiFix from '@/components/shared/view/UiFix';
import { Text } from '@/components/elemental/text/Text';
import { FluidElement, Spatial } from './FluidElement';

// variable
export default {
  name: 'FluidElement',
  mixins: [MixinProps, SerialLayoutInitialization],
  emits: ['fluidElementChanged', 'fluidElementRemoved', 'activeSpatialChangeFinished'],
  props: {
    element: {
      type: Object as PropType<FluidElement>,
      required: true,
    },
    container: {
      type: Object as PropType<FluidContainer>,
      required: true,
    },
    containerDomRef: {
      type: Object,
      required: false,
    },
    intervalHorizontal: {
      type: Number,
      required: true,
    },
    intervalVertical: {
      type: Number,
      required: true,
    }
  },
  components: {
    BlockContainer,
    ComponentWrapper,
    Draggable
  },
  setup(props: any, context: any) {
    const refElement = reactive(props.element) as FluidElement;
    const refContext = props.context as Context;

    const refDomMenu = ref<HTMLElement|null>(null);

    onMounted(() => {
      // Initialize spatial
      // console.log(refElement.spatial.displayed.y, refElement.spatial.yDesired);
      refElement.spatial.displayed.from(refElement.spatial.desired);
      ///////////////////////////////
      // Disable swipe to navigate. Otherwise it will break the menu button.
      const { preventEdgeSwipe } = useUiFix();
      if (refDomMenu.value){
        preventEdgeSwipe(refDomMenu.value);
      }
      ///////////////////////////////
      const observer = new IntersectionObserver(entries => {
        entries;
        if(refDomContent.value){
          refDomContent.value.classList.toggle( 'Animation', entries[0].isIntersecting );
          // TODO: unobserve?
          // observer.unobserve(refDomContent.value);
        }
      })
      observer.observe(refDomContent.value!);
    });

    const { emitLayoutInitialized, refDomContent} = useSerialLayoutInitialization(props.item, context);

    let timeLastUpdate = 0;
    watch(refElement.item, ()=>{
      timeLastUpdate = new Date().getTime();
    });

    // ifPushing: if the spatial, e.g. height, is changed passively, not by dragging.
    const emitFluidElementChanged = (oldSpatial: Spatial, ifPushing: boolean, ifChangeDesired: boolean = false) => {
      context.emit('fluidElementChanged', refElement, oldSpatial, ifPushing, ifChangeDesired);
    };

    watch(
      ()=>refElement.spatial.ifStickToContentHeight,
      ()=>{
        emitFluidElementChanged(refElement.spatial, true, true);
      },
    )

    watch(
      ()=>[refElement.spatial.sourceAnchorVerticalPosition, refElement.spatial.targetAnchorVerticalPosition],
      ()=>{
        emitFluidElementChanged(refElement.spatial, true, false);
      },
    )

    const onChildLayoutInitialized = () => {
      emitLayoutInitialized();
    }

    const onLayoutMinHeightChanged = (item: BaseItem, newHeight: number) => {
      const oldSpatial = refElement.spatial.clone();
      refContentHeightPx.value = newHeight;
      // refContentHeightPx.value = Math.ceil(refContentHeightPx.value / props.intervalVertical) * props.intervalVertical;
      /* eslint-disable vue/no-mutating-props */
      refElement.spatial.contentHeight = refContentHeightPx.value;
      if (dragDistance>0){
        refElement.spatial.displayed.height = Math.max(refElement.spatial.contentHeight, refElement.spatial.displayed.height);
      } else {
        refElement.spatial.displayed.height = Math.max(refElement.spatial.contentHeight, refElement.spatial.desired.height);
      }
      /* eslint-enable vue/no-mutating-props */

      if (refElement.spatial.ifStickToContentHeight){
        refElement.spatial.stickToContentHeight();
      }

      if (!refElement.spatial.equals(oldSpatial)){
        let ifPushing = true; // Defaults to push, so layout change -> push.
        let ifChangeDesired = false;
        const isDragging = dragDistance>0;
        // If dragging -> no push.
        if (isDragging){
          ifPushing = false;
          ifChangeDesired = false;
        } else if (refContext.selection.item?.ref === refElement.item.ref && refContext.selection.item?.getMeta() == Text.meta){
          // If the item is text and the change comes from text editing -> push.
          const timeHeightChanged = new Date().getTime();
          // console.log(timeHeightChanged, timeLastUpdate);
          if (timeHeightChanged - timeLastUpdate < 20){ // text change -> height change = about 10ms -> set to 20ms.
            // refElement.spatial.desired.height = refElement.spatial.displayed.height;
            ifPushing = true;
            ifChangeDesired = false;
          }
        }
        emitFluidElementChanged(oldSpatial, ifPushing, ifChangeDesired);
      }
    }

    const onLayoutMinWidthChanged = (item: BaseItem, newWidth: number) => {
      const oldSpatial = refElement.spatial.clone();
      refContentWidthPercent.value = Math.ceil(newWidth / props.intervalHorizontal) * props.intervalHorizontal;
      /* eslint-disable vue/no-mutating-props */
      refElement.spatial.displayed.width = Math.max(refElement.spatial.displayed.width, refContentWidthPercent.value);
      refElement.spatial.contentWidth = refContentWidthPercent.value;
      /* eslint-enable vue/no-mutating-props */

      if (!refElement.spatial.equals(oldSpatial)){
        emitFluidElementChanged(oldSpatial, true);
      }
    }

    // Initialize item content height to spatial height.
    // Text can change content height based on given width.
    const refContentHeightPx = ref(refElement.spatial.contentHeight);
    const refContentWidthPercent = ref(refElement.spatial.contentWidth);

    const displayOnStartedDragging = refElement.spatial.displayed.clone();
    const onStartedDragging = () => {
      displayOnStartedDragging.from(refElement.spatial.displayed);
    };

    const onDraggedPosition = (id: number, offsetX: number, offsetY: number) => {
      dragDistance += Math.sqrt(offsetX*offsetX + offsetY*offsetY);

      const oldSpatial = refElement.spatial.clone();
      const containerWidth = (props.containerDomRef as HTMLElement).getBoundingClientRect().width;
      /* eslint-disable vue/no-mutating-props */
      refElement.spatial.displayed.x = refElement.spatial.displayed.x + offsetX / containerWidth;
      refElement.spatial.displayed.x = refElement.spatial.displayed.x < 0 ? 0 : refElement.spatial.displayed.x;
      refElement.spatial.displayed.x = refElement.spatial.displayed.x + refElement.spatial.displayed.width > 1 ? 1 - refElement.spatial.displayed.width : refElement.spatial.displayed.x;
      refElement.spatial.displayed.y = refElement.spatial.displayed.y + offsetY / refContext.displayOption.scale;
      refElement.spatial.displayed.y = refElement.spatial.displayed.y < 0 ? 0 : refElement.spatial.displayed.y;
      /* eslint-enable vue/no-mutating-props */

      if (!refElement.spatial.equals(oldSpatial))
        emitFluidElementChanged(oldSpatial, false, false);
    };

    // Distance of dragging, reset after the dragging is complete.
    let dragDistance = 0;

    const onDraggedSize = (id: number, offsetX: number, offsetY: number) => {
      dragDistance += Math.sqrt(offsetX*offsetX + offsetY*offsetY);

      const oldSpatial = refElement.spatial.clone();
      const containerWidth = (props.containerDomRef as HTMLElement).getBoundingClientRect().width;
      /* eslint-disable vue/no-mutating-props */
      refElement.spatial.displayed.width = refElement.spatial.displayed.width + offsetX / containerWidth;
      refElement.spatial.displayed.width = refElement.spatial.displayed.width < props.intervalHorizontal ? props.intervalHorizontal : refElement.spatial.displayed.width;
      refElement.spatial.displayed.width = refElement.spatial.displayed.x + refElement.spatial.displayed.width > 1 ? 1 - refElement.spatial.displayed.x : refElement.spatial.displayed.width;
      refElement.spatial.displayed.width = Math.max(refElement.spatial.displayed.width, refElement.spatial.contentWidth);

      refElement.spatial.displayed.height = refElement.spatial.displayed.height + offsetY / refContext.displayOption.scale;
      refElement.spatial.displayed.height = refElement.spatial.displayed.height < props.intervalVertical ? props.intervalVertical : refElement.spatial.displayed.height;
      refElement.spatial.displayed.height = refElement.spatial.displayed.height < refContentHeightPx.value ? refContentHeightPx.value : refElement.spatial.displayed.height;

      // refElement.spatial.desired.height = refElement.spatial.displayed.height;
      /* eslint-enable vue/no-mutating-props */

      if (refElement.spatial.ifStickToContentHeight){
        refElement.spatial.stickToContentHeight();
      }

      if (!refElement.spatial.equals(oldSpatial))
        emitFluidElementChanged(oldSpatial, false, false);
    };

    const onDraggedPositionAndSize = (id: number, offsetX: number, offsetY: number) => {
      const exceedTopEdge = refElement.spatial.displayed.y + offsetY < 0;
      const exceedMinHeight = refElement.spatial.displayed.height - offsetY < props.intervalVertical;
      const exceedContentHeight = refElement.spatial.displayed.height - offsetY < refElement.spatial.contentHeight;
      if ( exceedTopEdge || exceedMinHeight || exceedContentHeight || refElement.spatial.ifStickToContentHeight ){
        offsetY = 0;
      }
      const containerWidth = (props.containerDomRef as HTMLElement).getBoundingClientRect().width;
      const exceedLeftEdge = refElement.spatial.displayed.x + offsetX / containerWidth < 0;
      const exceedMinWidth = (refElement.spatial.displayed.width - offsetX / containerWidth) < props.intervalHorizontal;
      const exceedContentWidth = (refElement.spatial.displayed.width - offsetX / containerWidth) < refElement.spatial.contentWidth;
      if ( exceedLeftEdge || exceedMinWidth || exceedContentWidth){
        offsetX = 0;
      }
      if (offsetX<0){
        onDraggedPosition(id, offsetX, offsetY);
        onDraggedSize(id, -offsetX, -offsetY);
      } else {
        onDraggedSize(id, -offsetX, -offsetY);
        onDraggedPosition(id, offsetX, offsetY);
      }
    };

    const onFinishedDragging = () => {
      // On iOS touch event could be triggered, if the drag distance is short.
      document.addEventListener('touchend', filterTouch);
      // reset after 10ms
      setTimeout(()=>
        {
          dragDistance = 0;
          document.removeEventListener('touchend', filterTouch);
        },
        10
      );
      if(dragDistance==0){
        return;
      }

      const oldSpatial = refElement.spatial.clone();

      refElement.spatial.displayed.x = Math.round(refElement.spatial.displayed.x / props.intervalHorizontal) * props.intervalHorizontal;
      refElement.spatial.displayed.width = Math.round(refElement.spatial.displayed.width / props.intervalHorizontal) * props.intervalHorizontal;
      refElement.spatial.displayed.y = Math.round(refElement.spatial.displayed.y / props.intervalVertical) * props.intervalVertical;
      // refElement.spatial.displayed.height = Math.round(refElement.spatial.displayed.height / props.intervalVertical) * props.intervalVertical;

      if (!refElement.spatial.displayed.equals(displayOnStartedDragging)){
        emitFluidElementChanged(oldSpatial, false, true);
      }

      context.emit('activeSpatialChangeFinished', refElement);
    };

    const filterTouch = (event: any) => {
      if (dragDistance>0)
        event.preventDefault();
      else
        return true;
    };

    const callbacks = {
      onChildLayoutInitialized,
      onLayoutMinHeightChanged,
      onLayoutMinWidthChanged,
      onStartedDragging,
      onDraggedPosition,
      onDraggedSize,
      onDraggedPositionAndSize,
      onFinishedDragging,
    };

    const refShowMoreOptions = ref(false);

    watch(
      () => refContext.uiState.getLastRequestEditFluid(),
      () => {
        if (refContext.uiState.getLastRequestEditFluid().element && refContext.uiState.getLastRequestEditFluid().element!.ref === props.element.ref){
          refShowMoreOptions.value = true;
        } else {
          refShowMoreOptions.value = false;
        }
      },
    );

    watch(
      () => refContext.selection.item,
      () => {
        if (refContext.selection.item == null || refContext.selection.item.ref !== props.element.ref){
          refShowMoreOptions.value = false;
        }
      },
    );

    const toggleMoreOptions = ()=>{
      // If the button is dragged with mouse, the click event will be triggered.
      if (dragDistance>0)
        return;

      refShowMoreOptions.value = !refShowMoreOptions.value;
      if (refShowMoreOptions.value){
        refContext.uiState.requestEditFluid(props.container, props.element);
      }
    };

    const actions = {
      toggleMoreOptions,
    };

    const computedAllowDragging = computed((): boolean => {
      if (!refContext.mode.editLayout || !refContext.selection.item){
        return false;
      } else if (refElement.item == refContext.selection.item)
        return true;
      else {
        // return refElement.item.contains(refContext.selection.item).length>0;
        return false;
      }
    });

    const computedAllowStickingToContentHeight = computed((): boolean => {
      return refElement.spatial.contentHeight>0;
    });

    return {
      props,
      refElement,
      refContext,
      refDomContent,
      refDomMenu,
      refShowMoreOptions,
      ...callbacks,
      ...actions,
      computedAllowDragging,
      computedAllowStickingToContentHeight,
    };
  }
}
