
import { Ref, computed, onMounted, ref, toRef } from 'vue';
import MixinProps from '@/components/shared/view/MixinProps';
import useCollection from '@/components/elemental/collection/view/shared/useCollection';
import { BaseItem } from '@/components/shared/model/BaseItem';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import AddItemButton from "@/components/shared/util/AddItemButton.vue";
import Context from '@/components/shared/view/Context';
import { Page } from '../../Page';
import { scrollToItem } from '@/components/shared/view/AutoScroll';
import {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';

export default {
  name: 'PageViewer',
  mixins: [MixinProps],

  components: {
    ComponentContainer,
    ComponentWrapper,
    AddItemButton,
  },
  setup(props: any, context: any){
    const refPage = toRef(props, 'item') as Ref<Page>;
    const refContext = toRef(props, 'context') as Ref<Context>;

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refPage.value, context);

    const addNewItemAt = (item: BaseItem, index: number)=>{
      useCollection(refPage.value.content).addNewItemAt(item, index);
      ++refCurrentRange.value;
    }

    onMounted(()=>{
      document.title = refPage.value.title.getValueOf(refContext.value.language);
      // Listen to scroll
      // (refDomContentContainer.value as HTMLElement).addEventListener('scroll', function(e: any) {
      //   e;
      // });
    });

    // actions:
    const moveElementForwards = (element: BaseItem)=>{
      refPage.value.content.moveElementForwards(element);
    }
    const moveElementBackwards = (element: BaseItem)=>{
      refPage.value.content.moveElementBackwards(element);
    }
    const removeElementAt = (idx: number)=>{
      refPage.value.content.removeElementAt(idx);
      --refCurrentRange.value;
    }

    const refShowMoreOptions = ref(false);

    const toggleMoreOptions = ()=>{
      refShowMoreOptions.value = !refShowMoreOptions.value;
    };

    const actions = {addNewItemAt, moveElementForwards, moveElementBackwards, removeElementAt, toggleMoreOptions};

    const computedIfEditing = computed( () : boolean => {
      return refContext.value.mode.editContent || refContext.value.mode.editTextInline;
    });

    const selectAndScrollTo = (item: BaseItem)=>{
      refContext.value.setSelectedItem(item);
      refContext.value.uiState.requestEditType('Style');

      scrollToItem(item);
    };


    const refCurrentRange = ref( Math.min(1, refPage.value.content.elements.length));

    const onChildLayoutInitialized = (child: BaseItem)=>{
      child;
      // console.log("Page: onChildLayoutInitialized: ", child.getMeta().typeName);
      if (refCurrentRange.value == refPage.value.content.elements.length){
        console.log("Page: all children finished initialization");
        emitLayoutInitialized();
      } else {
        ++refCurrentRange.value;
      }
    };

    return {
      props,
      refPage,
      refContext,
      refShowMoreOptions,
      ...actions,
      computedIfEditing,
      selectAndScrollTo,

      onChildLayoutInitialized,
      refCurrentRange
    }
  }
}
