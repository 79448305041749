import { BaseDefinition } from "@/components/shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PageRef } from "@/components/page/pageRef/PageRef";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import { PropertyUrl } from "@/components/shared/property/propertyUrl/PropertyUrl";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";
import { Language } from "@/components/shared/model/BaseVars";

export
class PageNode extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "PageNode", friendlyName: "Page Node"};

  static readonly nameTitleNavigation: string = "TitleNavigation";
  static readonly nameRef: string = "PageRef";
  static readonly nameUrl: string = "PageUrl";

  titleNavigation = new PropertyString(PageNode.nameTitleNavigation);
  pageRef = new PageRef(PageNode.nameRef);
  url = new PropertyUrl(PageNode.nameUrl);

  public getStoreObjectItem(): Object{
    return {
      titleNavigation: this.titleNavigation.getStoreObject(),
      pageRef: this.pageRef.getStoreObject(),
      url: this.url.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.titleNavigation.fromStoreObject(item.titleNavigation);
    this.pageRef.fromStoreObject(item.pageRef);
    this.url.fromStoreObject(item.url);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
    this.url.setValueOf(Language.default, "/"+this.ref);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const pageNode = item as PageNode;
    this.titleNavigation.from(pageNode.titleNavigation);
    this.pageRef.from(pageNode.pageRef);
    this.url.from(pageNode.url);
  }

  getMeta(): MetaInfo{
    return PageNode.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new PageNode();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}